import type { AccessDelegationImpersonation } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { Badge } from "../../../components/Badge.tsx";
import { ContentPane } from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { InfoIcon, WTooltip } from "../../../components/WTooltip.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../../components/table/DetailsTableBillingAccount.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation, useStandardSuspenseQuery } from "../../api/useStandardMutation.ts";
import { scopeLabel } from "../scopeLabel.tsx";
import { AddImpersonationForm } from "./AddImpersonationForm.tsx";
import {
    accessDelegationListQuery,
    deleteAccessDelegationMutation,
    deleteImpersonatorMutation,
    type AccessDelegationWithType,
} from "./apiOperations.ts";

export function DelegationView({ accessId }: { accessId: number }) {
    //#region Hooks
    const deleteMutation = useStandardMutation(deleteAccessDelegationMutation);

    const { data } = useStandardSuspenseQuery(accessDelegationListQuery);
    const obj = useDeletableResource(() => getResourceById(data, accessId, "access_delegation"), deleteMutation);
    //#endregion

    if (obj === undefined) {
        return <NavigateAfterDelete />;
    }

    const { $type, id, name, restricted, created_at, billing_account_id } = obj;

    async function onDelete() {
        await deleteMutation.mutateAsync({ id });
    }

    return (
        <>
            <Toolbar>
                <DeleteModal title="Delete Access Group" modalAction={onDelete}>
                    Deleting "{name}" will permanently remove access from all the users in this group to act as you.
                </DeleteModal>
            </Toolbar>

            <ContentPane>
                <ViewTitle title={name} />

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableRow title="Access name:">{name}</DetailsTableRow>
                        <DetailsTableRow title="Access type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Access scope:">{scopeLabel(restricted)}</DetailsTableRow>
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <DetailsTableRow />
                        <DetailsTableBillingAccount isGlobal={!restricted} valueKey={billing_account_id} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Users who can access my account</h2>
                <ImpersonationsTable obj={obj} />
            </ContentPane>
        </>
    );
}

function ImpersonationsTable({ obj }: { obj: AccessDelegationWithType }) {
    const deleteImpersonationMutation = useStandardMutation(deleteImpersonatorMutation);
    const [isVisible, setIsVisible] = useState(false);

    const { id, impersonations } = obj;

    async function onImpersonationDelete(impersonation: AccessDelegationImpersonation) {
        await deleteImpersonationMutation.mutateAsync({
            impersonation_id: impersonation.uuid,
            delegation_id: id,
        });
    }

    return (
        <WTable
            afterTable={
                !isVisible && (
                    <WButton
                        color="primary"
                        size="bar"
                        variant="border"
                        icon="jp-icon-add"
                        data-testid="AddImpersonationButton"
                        action={() => setIsVisible(!isVisible)}
                    >
                        Add Users
                    </WButton>
                )
            }
        >
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th />
                </tr>
            </thead>
            <WTableBody>
                {impersonations.map((item) => (
                    <tr key={item.uuid}>
                        <td>{item.grantee_username}</td>
                        <td>
                            {item.is_accepted ? (
                                <Badge color="success">Accepted</Badge>
                            ) : (
                                <WTooltip text="User must accept access invitation from their profile view">
                                    <Badge color="warning">Pending</Badge>
                                    <InfoIcon className="ml-2" />
                                </WTooltip>
                            )}
                        </td>
                        <td>
                            <LongDate date={item.created_at} />
                        </td>
                        <td className="text-right">
                            <DeleteModal
                                title="Remove User"
                                inTable
                                modalAction={async () => await onImpersonationDelete(item)}
                            >
                                Removing user will revoke the rights from "{item.grantee_username}" to act as you.
                            </DeleteModal>
                        </td>
                    </tr>
                ))}
            </WTableBody>

            {isVisible && (
                <tfoot>
                    <tr>
                        <td colSpan={4}>
                            <AddImpersonationForm obj={obj} onClose={() => setIsVisible(false)} />
                        </td>
                    </tr>
                </tfoot>
            )}
        </WTable>
    );
}
