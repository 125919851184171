import { ContentPane } from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { SecretKey } from "../../../components/SecretKey.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../../components/table/DetailsTableBillingAccount.tsx";
import { DetailsTableName } from "../../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { changedFields } from "../../../utils/changedFields.ts";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation, useStandardSuspenseQuery } from "../../api/useStandardMutation.ts";
import { scopeLabel } from "../scopeLabel.tsx";
import { ApiDocLink } from "./ApiDocLink.tsx";
import {
    apiTokenListQuery,
    deleteApiTokenMutation,
    modifyApiTokenMutation,
    type ApiTokenWithType,
} from "./apiOperations.ts";

export function TokenView({ accessId }: { accessId: number }) {
    //#region Hooks
    const deleteMutation = useStandardMutation(deleteApiTokenMutation);
    const modifyMutation = useStandardMutation(modifyApiTokenMutation);

    const { data } = useStandardSuspenseQuery(apiTokenListQuery);

    const obj = useDeletableResource(() => getResourceById(data, accessId, "api_token"), deleteMutation);
    //#endregion

    if (obj === undefined) {
        return <NavigateAfterDelete />;
    }

    const { $type, id, description, restricted, created_at, billing_account_id, token } = obj;

    async function onDelete() {
        await deleteMutation.mutateAsync({ id });
    }

    // NB: Can not use `function` since it will be hoisted and `obj` will be `undefined`.
    const onModify = async (updatedFields: Partial<Pick<ApiTokenWithType, "description" | "billing_account_id">>) => {
        const changed = changedFields(obj, updatedFields);
        if (!changed) {
            return;
        }

        await modifyMutation.mutateAsync({
            body: {
                ...changed,
                token_id: id,
                restricted,
            },
        });
    };

    return (
        <>
            <Toolbar>
                <DeleteModal title="Delete Token" modalAction={onDelete}>
                    Deleting a token "{description}" will permanently remove it and cannot be used for authentication
                    after that.
                </DeleteModal>
            </Toolbar>

            <ContentPane>
                <ViewTitle title={description} />

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableName
                            title="Access name:"
                            value={description}
                            isRequired
                            onChange={async (e) => await onModify({ description: e })}
                        />
                        <DetailsTableRow title="Access type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Access scope:">{scopeLabel(restricted)}</DetailsTableRow>
                        <DetailsTableRow title="Token:">
                            <SecretKey value={token} />
                        </DetailsTableRow>
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <DetailsTableRow />
                        <DetailsTableBillingAccount
                            isGlobal={!restricted}
                            valueKey={billing_account_id}
                            allowClosedAccounts
                            onChange={async (item) => await onModify({ billing_account_id: item.id })}
                        />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>

            <Separator />

            <ContentPane>
                <p className="text-muted">
                    Comprehensive API manual with code samples is available here:
                    <br />
                    <ApiDocLink />
                </p>
            </ContentPane>
        </>
    );
}
