import { getResourceLink, type AnyResource } from "../modules/api/resourceTypes.tsx";
import { WTooltip } from "./WTooltip.tsx";
import { WButton } from "./button/WButton.tsx";

export function ResourceLink({ title, item }: { title?: string; item: AnyResource }) {
    const action = getResourceLink(item);

    return (
        <WTooltip text="View">
            <WButton action={action} size="xs" color="primary" variant="ghost">
                {title}
            </WButton>
        </WTooltip>
    );
}
